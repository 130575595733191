import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProductService from '../services/ProductService';
import ProductList from './ProductList'; // Import the ProductList component


function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }
  
//   function renderComponentForCategory(category) {
//     // Add your logic to render the specific component for the category
//     // Replace this with your own implementation
//     return <div>{category.category}</div>;
//   }

  
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ScrollableTabsButtonForce() {
  const [value, setValue] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const productService = new ProductService();

    productService
      .fetchProductCategories()
      .then((data) => {
        setCategories(data.data); // Assuming the API response has a "data" property
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="tab_items">
        <div className="container">
          <div className="mb-3 ps-3">
            <h1 className="text-center">Our Products</h1>
          </div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example"
              >
                {categories.map((category, index) => (
                  <Tab
                    label={category.category}
                    {...a11yProps(index)}
                    key={index}
                  />
                ))}
              </Tabs>
            </Box>
            <div className="tab_content">
              {categories.map((category, index) => (
                <TabPanel value={value} index={index} key={index}>
                  {/* <h2>{category.category}</h2> */}
                  {/* Render the specific component for the category */}
                  {/* {renderComponentForCategory(category)} */}
                  <ProductList category={category.categorykey} />
                </TabPanel>
              ))}
            </div>
          </Box>
        </div>
      </section>
    </>
  );
}
