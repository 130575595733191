import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';
import defaultuserlogo from '../assets/img/users/default.png';
import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import PaymentService from '../services/PaymentService';

function PaymentStatus() {
    
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false); // Add a state to track if the isLoggedIn check is complete
    const [apiData, setApiData] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('Payment Unsuccessful');
    const [paymentStatusClass, setPaymentStatusClass] = useState('error');

    // Accessing query parameter
    const queryParams = new URLSearchParams(location.search);
    const paymentIdFromQuery = queryParams.get('payment_id');
    const paymentStatusFromQuery = queryParams.get('payment_status');
    const paymentRequestIdFromQuery = queryParams.get('payment_request_id');
    
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
        }
    }, [navigate]);

    useEffect(() => {
        // Call your API service here
        if (paymentIdFromQuery) {
            const PaymentServiceInstance = new PaymentService();
            const fetchData = async () => {
                try {
                    var user_id = localStorage.getItem('userid');
                    var session_key = localStorage.getItem('session_key');

                    // You can construct your request data based on the query parameters
                    const requestData = {
                        payment_id: paymentIdFromQuery,
                        payment_status: paymentStatusFromQuery,
                        payment_request_id: paymentRequestIdFromQuery,
                        user_id: user_id, // Make sure user_id is defined
                        sessionkey: session_key, // Make sure session_id is defined
                    };
    
                    const response = await PaymentServiceInstance.payment_response(requestData);
                    // Handle the response data as needed
                    setApiData(response.data);
                    if (response.status) {
                        setPaymentStatus(response.message);
                        setPaymentStatusClass(response.status ? 'success' : 'error');
                    } else {
                        setPaymentStatus('API Error');
                        setPaymentStatusClass('error');
                    }

                } catch (error) {
                    setPaymentStatus('API Error');
                    setPaymentStatusClass('error');
                }
            };
    
            fetchData();
        }
    }, [paymentIdFromQuery,paymentStatusFromQuery,paymentRequestIdFromQuery]);
   
    // Render a loading indicator while the isLoggedIn check is in progress
    if (!isLoggedInChecked) {
        return <div>Loading...</div>;
    }


    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>Payment Status</h1>
                    <nav class="breadcrumb ps-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">Payment Status</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className={paymentStatusClass}>{paymentStatus}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                        <h5>Continue Shopping</h5>
                        <NavLink class="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                    </div>
            
                </div>
            </section>
        </>
    )
}

export default PaymentStatus