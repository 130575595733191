export const ADD = (item) =>{
    return{
        type:"ADD_CART",
        payload:item
    }
}
export const ADD1 = (item1) =>{
    return{
        type:"ADD_PRODUCT",
        payload:item1
    }
}