import React from 'react'
import banner1 from '../assets/img/banner/all-product.jpg'
import banner2 from '../assets/img/banner/horticulture.jpg'
import banner3 from '../assets/img/banner/Nursery.jpg'
import banner4 from '../assets/img/banner/vegetable.webp'
import banner5 from '../assets/img/banner/flower.jpg'

import { MdWifiCalling2 } from 'react-icons/md';



function Myslider() {



  return (
    <>
      <section>
        <div className=''>
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className={`carousel-item active`}>
                <div className="slide1">
                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center flex-column order-2 order-lg-1">
                      <div className='p-5 p-lg-1 d-block m-auto' style={{ maxWidth: '100%', width: '500px' }}>
                        <h3>Liebigs Agro chem</h3>
                        <p style={{fontSize:'20px'}}>
                          Are you a farmer, wholesaler, distributor, or a home gardener seeking to unlock the full potential of your agricultural endeavors? your one-stop destination for all your agriculture needs.
                        </p>
                        <div className='p-3'>
                          <a href="tel:+918100503421">
                            <button className='btn_1 d-block ms-auto'><MdWifiCalling2 /> Call Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <div>
                        <img src={banner1} alt="b1" className='img-fluid s_img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slide2">
                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center flex-column order-2 order-lg-1">
                      <div className='p-5 p-lg-1 d-block m-auto' style={{ maxWidth: '100%', width: '500px' }}>
                        <h5>We deal with Landscaping, Planning, Designing & Maintenance of Commercial Garden</h5>
                        <b>
                          We are expert in-
                        </b>
                        <li>Softscaping -Total garden development.</li>
                        <li>Hardscaping-Various structure & features, sculptures, path way, kiosk, design based pergola etc.</li>
                        <li>Complete solution of irrigation systems from Drip to Micro.</li>
                        <div className='p-3'>
                          <a href="tel:+918100503421">
                            <button className='btn_1 d-block ms-auto'><MdWifiCalling2 /> Call Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <div>
                        <img src={banner2} alt="b1" className='img-fluid s_img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slide3">
                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center flex-column order-2 order-lg-1">
                      <div className='p-5 p-lg-1 d-block m-auto' style={{ maxWidth: '100%', width: '500px' }}>
                        <h3>We have started our own nursery division for both wholesale and retail businesses.</h3>
                        <b style={{ fontSize: '20px' }}>We have excellent collection of –</b>
                        <p style={{ fontSize: '20px' }}>Cactus |
                          Succulent | Aglaonema | Grafted Hibiscus | Ornamental Plants | Fruit Plants
                        </p>
                        <div className='p-3'>
                          <a href="tel:+918100503421">
                            <button className='btn_1 d-block ms-auto'><MdWifiCalling2 /> Call Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <div>
                        <img src={banner3} alt="b1" className='img-fluid s_img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slide4">
                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center flex-column order-2 order-lg-1">
                      <div className='p-5 p-lg-1 d-block m-auto' style={{ maxWidth: '100%', width: '500px' }}>
                        <h3>We provide the following top quality vegetable seeds</h3>
                        <p>
                          We have entered in this area of business with our own brand of seeds and dealing with a wide range of F1, & open pollinated seeds. We are procuring these seeds from different reputed seed breeders, testing them in our own trial field, selecting the ideal varieties & marketing them. Within a very short period of time we are receiving customer patronization. Though we are only scratching in this field but we believe that we shall be able to create recognizable foot mark in this field also.
                        </p>
                        <div className='p-3'>
                          <a href="tel:+918100503421">
                            <button className='btn_1 d-block ms-auto'><MdWifiCalling2 /> Call Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <div>
                        <img src={banner4} alt="b1" className='img-fluid s_img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slide5">
                  <div className="row">
                    <div className="col-lg-6 d-flex justify-content-center flex-column order-2 order-lg-1">
                      <div className='p-5 p-lg-1 d-block m-auto' style={{ maxWidth: '100%', width: '500px' }}>
                        <h3>Growing color and nutrients with our seeds from Flower</h3>
                        <p>
                          We are engaged in importing flower seeds from different world leader Companies like Gold smith of USA, Benary of Germany etc. for more than a decade. We almost cover entire India with our flower seeds.
                        </p>
                        <div className='p-3'>
                          <a href="tel:+918100503421">
                            <button className='btn_1 d-block ms-auto'><MdWifiCalling2 /> Call Now</button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                      <div>
                        <img src={banner5} alt="b1" className='img-fluid s_img' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

    </>
  )
}

export default Myslider