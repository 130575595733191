import { combineReducers } from "redux";
import { cartreducer } from "./reducers";

const rootred = combineReducers({
    cartreducer
});

export default rootred;

// // main.js
// import { combineReducers } from "redux";
// import cartReducer from "./cartReducer"; // Import as default

// const rootReducer = combineReducers({
//     cartReducer
// });

// export default rootReducer;
