
const PlantsData = [
    {
        id : 1,
        setImg : "../img/plant1.jpg",
        alt : "plant-1 ",
        name : 'Plants 1 bv cbcb nvmhg  ngnv ngn  gncnc vfgjfn vmnhvmh',
        price : '499',
        qty : 0
    },
    {
        id : 2,
        setImg : "../img/plant2.jpg",
        alt : "plant-2",
        name : 'Plants 2',
        price : '480',
        qty : 0
    },
    {
        id : 3,
        setImg : "../img/plant3.jpg",
        alt : "plant-3",
        name : 'Plants 3',
        price : '150',
        qty : 0
    },
    {
        id : 4,
        setImg : "../img/plant4.jpg",
        alt : "plant-4",
        name : 'Plants 4',
        price : '250',
        qty : 0
    },
    {
        id : 5,
        setImg : "../img/plant4.jpg",
        alt : "plant-5",
        name : 'Plants 5',
        price : '556',
        qty : 0
    },
    {
        id : 6,
        setImg : "../img/plant6.jpg",
        alt : "plant-6",
        name : 'Plants 6',
        price : '865',
        qty : 0
    },

];
export default PlantsData;

const vegetableData = [
  {
    id: 1,
    setImg: 'img/seed1.jpg',
    alt : "seed1",
    name : 'seed-1',
    price : '130',
    qty : 0
  },
  {
    id: 2,
    setImg: 'img/seed2.jpg',
    alt : "seed2",
    name : 'seed-2',
    price : '110',
    qty : 0
  
  },
  {
    id: 3,
    setImg: 'img/seed3.jpg',
    alt : "seed3",
    name : 'seed-3',
    price : '120',
    qty : 0
  },
  {
    id: 4,
    setImg: 'img/seed4.jpg',
    alt : "seed4",
    name : 'seed-4',
    price : '120',
    qty : 0
  },
];

export {vegetableData};

const agroProducts = [
  {
    id: 1,
    setImg: '../img/agro1.jpg',
    alt : "Agro1",
    name : 'Agro Products-1',
    price : '130',
    qty : 0
  },
  {
    id: 2,
    setImg: '../img/agro2.jpg',
    alt : "Agro2",
    name : 'Agro Products-2',
    price : '110',
    qty : 0
  
  },
  {
    id: 3,
    setImg: '../img/agro3.jpg',
    alt : "Agro3",
    name : 'Agro Products-3',
    price : '120',
    qty : 0
  },
  {
    id: 4,
    setImg: '../img/agro4.jpg',
    alt : "Agro4",
    name : 'Agro Products-4',
    price : '120',
    qty : 0
  },
  {
    id: 5,
    setImg: '../img/agro5.jpg',
    alt : "Agro4",
    name : 'Agro Products-4',
    price : '120',
    qty : 0
  },
];

export {agroProducts};

const teaProducts = [
  {
    id:1,
    setImg: '../img/tea-elixir.jpg',
    alt : "tea1",
    name : 'Tea Products-1',
    price : '120',
    qty : 0
  }
];
export {teaProducts};


const itemData = [
    {
      id:2,
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    
    },
    {
      id:3,
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      id:4,
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      id:5,
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      id:6,
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      id:7,
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      id:8,
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      id:9,
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      id:10,
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',

    },
    {
      id:11,
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];

  export {itemData};

  
 