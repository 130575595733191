import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProductService from '../services/ProductService';
import BulkorderService from '../services/BulkorderService';

function Bulkorder() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [addedProducts, setAddedProducts] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        products: [
            {
                productId: '',
                unit: '',
                quantity: '',
            },
        ],
    });

    // Maintain an array of available units for each product
    const [productUnits, setProductUnits] = useState([]);

    // Validation error states
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [productError, setProductError] = useState('');
    const [unitError, setUnitError] = useState('');
    const [quantityError, setQuantityError] = useState('');

    useEffect(() => {
        const productService = new ProductService();
        // Fetch initial product details and units based on the id
        productService
            .fetchProductDetails(id)
            .then((productDetails) => {
                setProduct(productDetails);
                // Set the default selected unit and price based on the first unit if prices are available
                if (productDetails && productDetails.length > 0 && productDetails[0].prices) {
                    const initialUnitName = productDetails[0].prices[0].unit_name;
                    setProductUnits(productDetails.map((product) => product.prices.map((price) => price.unit_name)));
                    // Set the default unit for the first product
                    setFormData((prevState) => ({
                        ...prevState,
                        products: [{ ...prevState.products[0], unit: initialUnitName }],
                    }));
                }
            })
            .catch((error) => {
                console.error('Error fetching product details:', error);
            });

        // Fetch the list of available products
        productService
            .fetchProductsAll()
            .then((response) => {
                if (response.length > 0) {
                    setAddedProducts(response);
                } else {
                    console.error('Error fetching product list:', response.message);
                }
            })
            .catch((error) => {
                console.error('Error fetching product list:', error);
            });
    }, [id]);

    const handleProductChange = (e, productIndex) => {
        const productId = e.target.value;
        const updatedProducts = [...formData.products];
        updatedProducts[productIndex].productId = productId;
        const selectedProduct = addedProducts.find((product) => product.id === productId);

        if (selectedProduct) {
            // Set the available units for the specific product
            const productUnitsCopy = [...productUnits];
            productUnitsCopy[productIndex] = selectedProduct.prices.map((price) => price);
            setProductUnits(productUnitsCopy);
            updatedProducts[productIndex].unit = productUnitsCopy[productIndex][0]; // Default to the first unit
        }

        setFormData((prevState) => ({ ...prevState, products: updatedProducts }));
    };

    const handleAddProduct = () => {
        const updatedProducts = [...formData.products];
        updatedProducts.push({
            productId: '',
            unit: productUnits[0] && productUnits[0][0], // Default to the first unit if available
            quantity: '',
        });

        // Add an empty array for the new product's units
        const productUnitsCopy = [...productUnits];
        productUnitsCopy.push([]);
        setProductUnits(productUnitsCopy);

        setFormData((prevState) => ({ ...prevState, products: updatedProducts }));
    };

    const handleRemoveProduct = (productIndex) => {
        const updatedProducts = [...formData.products];
        updatedProducts.splice(productIndex, 1);

        // Remove the corresponding product's units
        const productUnitsCopy = [...productUnits];
        productUnitsCopy.splice(productIndex, 1);

        setProductUnits(productUnitsCopy);

        setFormData((prevState) => ({ ...prevState, products: updatedProducts }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset validation errors
        setNameError('');
        setEmailError('');
        setProductError('');
        setUnitError('');
        setQuantityError('');

        // Validate name, email, and the first product
        let isValid = true;

        if (formData.name.trim() === '') {
            setNameError('Name is required.');
            isValid = false;
        }

        if (formData.email.trim() === '') {
            setEmailError('Email is required.');
            isValid = false;
        } else if (!isValidEmail(formData.email)) {
            setEmailError('Invalid email address.');
            isValid = false;
        }

        if (formData.products.length > 0) {
            const firstProduct = formData.products[0];

            if (firstProduct.productId === '') {
                setProductError('Please select a product for the first item.');
                isValid = false;
            }

            if (firstProduct.unit === '') {
                setUnitError('Please select a unit for the first item.');
                isValid = false;
            }

            if (firstProduct.quantity.trim() === '') {
                setQuantityError('Quantity is required for the first item.');
                isValid = false;
            } else if (isNaN(Number(firstProduct.quantity)) || Number(firstProduct.quantity) <= 0) {
                setQuantityError('Quantity must be a positive number.');
                isValid = false;
            }
        }

        if (isValid) {
            // Access the form data here
            console.log(formData);
            const user_id = localStorage.getItem('userid');
            const session_key = localStorage.getItem('session_key');
            formData.user_id = user_id;
            formData.sessionkey = session_key;

            const BulkorderServiceApp = new BulkorderService();
            const response = await BulkorderServiceApp.addtoBulkorder(formData);
            // You can send the form data to your backend or perform other actions
            // For example, you can send an HTTP request to submit the data.
            console.log('response',response)
            if (response.status==true) {
                alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                //window.location.reload();
                //navigate('/cart/');
                // Dispatch an action to add the product to the cart
                //dispatch(addToCartAction(productData)); 

            } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
            }
        }
    };

    const isValidEmail = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    if (!product) {
        // If the product is not found, you can show a loading indicator or an error message.
        return <div>Loading...</div>;
    }

    const bulkDiv = {
        background: '#fff',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '100%',
        width: '100vh',
        margin: 'auto',
    };

    return (
        <>
            <section className="p-3" style={{ background: '#f2f2f2' }}>
                <div className="container">
                    <div style={bulkDiv}>
                        <div>
                            <h1>Bulk Order</h1>
                        </div>
                        <hr />
                        <form action="/" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="mb-3">
                                        <label for="name" class="form-label">Name</label>
                                        <input type="text" class="form-control" id="name" placeholder="Enter your name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
                                        <div className="text-danger">{nameError}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email address</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Your Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
                                        <div className="text-danger">{emailError}</div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="mb-3">
                                        <label for="phone" class="form-label">Phone</label>
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Your Number" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
                                    </div>
                                </div>
                            </div>
                            {formData.products.map((productData, productIndex) => (
                                <div key={productIndex} className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor={`product-${productIndex}`} className="form-label">
                                                Product
                                            </label>
                                            <select
                                                id={`product-${productIndex}`}
                                                className="form-select"
                                                aria-label="Default select example"
                                                value={productData.productId}
                                                onChange={(e) => handleProductChange(e, productIndex)}
                                            >
                                                <option>Open this select product</option>
                                                {addedProducts.map((addedProduct) => (
                                                    <option key={addedProduct.id} value={addedProduct.id}>
                                                        {addedProduct.product_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {productIndex === 0 && <div className="text-danger">{productError}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor={`unit-${productIndex}`} className="form-label">
                                                Unit
                                            </label>
                                            <select
                                                id={`unit-${productIndex}`}
                                                className="form-select"
                                                aria-label="Default select example"
                                                value={productData.unit?.product_price_id}
                                                onChange={(e) => {
                                                    const unitValue = e.target.value;
                                                    const updatedProducts = [...formData.products];
                                                    updatedProducts[productIndex].unit = productUnits[productIndex].find(unit => unit.product_price_id === unitValue);
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        products: updatedProducts,
                                                    }));
                                                }}
                                            >
                                                <option>Open this select product unit</option>
                                                {productUnits[productIndex].map((unit, unitIndex) => (
                                                    <option key={unitIndex} value={unit.product_price_id}>
                                                        {unit.qty} {unit.unit_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {productIndex === 0 && <div className="text-danger">{unitError}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor={`quantity-${productIndex}`} className="form-label">
                                                Quantity
                                            </label>
                                            <input
                                                id={`quantity-${productIndex}`}
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter product quantity"
                                                value={productData.quantity}
                                                onChange={(e) => {
                                                    const quantityValue = e.target.value;
                                                    const updatedProducts = [...formData.products];
                                                    updatedProducts[productIndex].quantity = quantityValue;
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        products: updatedProducts,
                                                    }));
                                                }}
                                            />
                                            {productIndex === 0 && <div className="text-danger">{quantityError}</div>}
                                        </div>
                                    </div>
                                    {productIndex > 0 && (
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => handleRemoveProduct(productIndex)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                     )}
                                    <hr />
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-ghost-primary waves-effect waves-light"
                                            style={{ color: '#dc3545', fontWeight: 'bolder' }}
                                            onClick={handleAddProduct}
                                        >
                                            + Add More Products
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <button type="submit" className="btn_1">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Bulkorder;
