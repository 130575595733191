import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TransactionService from '../services/TransactionService';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(p_id, p_title,time, method, status, total,invoice) {
  return { p_id, p_title, time, method, status, total,invoice };
}

const rows = [
  createData('./img/agro1.jpg','Platipus Plants','02:30pm 02-11-23', 'COD', 'Complete',800,'invoice'),
  createData('./img/agro2.jpg','Donut', '02:30pm 02-11-23','Credit Card', 'Processing',500,'invoice'),
  createData('./img/agro3.jpg','Eclair','02:30pm 02-11-23', 'COD', 'Pending',564,'invoice'),
  createData('./img/agro4.jpg','Frozen yoghurt','02:30pm 02-11-23', 'COD', 'Complete',412,'invoice'),
  createData('./img/agro5.jpg','Gingerbread','02:30pm 02-11-23', 'COD', 'Complete',321,'invoice'),
  createData('./img/plant1.jpg','Honeycomb','02:30pm 02-11-23', 'UPI', 'Complete',475,'invoice'),
  createData('./img/plant2.jpg','Ice cream sandwich','02:30pm 02-11-23','COD', 'Complete',360,'invoice'),
  createData('./img/plant3.jpg','Jelly Bean','02:30pm 02-11-23', 'COD', 'Complete',299,'invoice'),
  createData('./img/seed1.jpg','KitKat','02:30pm 02-11-23', 'COD', 'Complete',550,'invoice'),
  createData('./img/plant5.jpg','Lollipop','02:30pm 02-11-23', 'UPI', 'Complete',175,'invoice'),
  createData('./img/plant6.jpg','Marshmallow','02:30pm 02-11-23', 'COD','Complete',250,'invoice'),
  createData('./img/seed2.jpg','Nougat', '02:30pm 02-11-23','COD', 'Complete',175,'invoice'),
  createData('./img/tea-elixir.jpg','Oreo','02:30pm 02-11-23', 'COD', 'Complete',150,'invoice'),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function MyTransaction() {

    const navigate = useNavigate(); // Initialize useNavigate hook

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [transactionData, setTransactionData] = React.useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
            
            // Fetch cart data using CartDataService when the component mounts
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
    
            const TransactionServiceInstance = new TransactionService();
    
            const sendTransactionData = {
                user_id: user_id,
                sessionkey: session_key,
                limit: ''
            };
    
            TransactionServiceInstance
                .transactionlist(sendTransactionData)
                .then((data) => {
                    if (Array.isArray(data.data)) {
                        setTransactionData(data.data);
                    } else if (data && data.orders) {
                        setTransactionData(data.orders); // Assuming orders is the array of orders inside data
                    } else {
                        console.error('Unexpected order data structure:', data);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching order data:', error);
                });
        }
    }, [navigate]);
    
    console.log('transactionData',transactionData);
  return (
    <>
    <section className='pt-3 pb-3'>
        <div className="container">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead style={{fontWeight:'bold'}}>
                    <TableRow style={{background:'aliceblue'}}>
                        <TableCell align="center" style={{fontWeight:'bold'}}>Transaction Time / Date</TableCell>
                        <TableCell align="center" style={{fontWeight:'bold'}}>Amount</TableCell>
                        <TableCell align="center" style={{fontWeight:'bold'}}>Transaction ID</TableCell>
                        <TableCell align="center" style={{fontWeight:'bold'}}>Payment Status</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.transaction_id}>
                        <TableCell align="left" scope="row" style={{width:280}} >
                            {row.transaction_date}
                        </TableCell>
                        <TableCell align="center">
                        ₹ {row.amount}
                        </TableCell>
                        <TableCell align="center">
                            {row.payment_id}
                        </TableCell>
                        <TableCell align="center">
                            {row.pay_status}
                        </TableCell>
                        {/* <TableCell align="center">
                        <button className='btn_3'>{row.invoice}</button> 
                        </TableCell> */}
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>
                    <TableFooter style={{overflow:'hidden', }}>
                    <TableRow>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                            'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    </section>
    </>
    
    
  );
}