import React, {useState}  from 'react';
import { Link, useNavigate, useParams, NavLink } from 'react-router-dom'; // Import Redirect
import Dashboard from './Dashboard';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';

import SignupService from '../services/SignupService';
import LoginService from '../services/LoginService';

function Signup() {

    const { param, param2 } = useParams();

    const initialFormData = {
        fullname: '',
        phonenumber: '',
        email: '',
        password: '',
        confirmpassword: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const urlparam = param ? param : '';
    const urlparam2 = param2 ? param2 : '';

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFormData(formData);
        setErrors(validationErrors);
        
        if (Object.keys(validationErrors).length === 0) {
            try {
                //console.log('formData',formData);
                const signupServiceInstance = new SignupService();
                const response = await signupServiceInstance.registersignup(formData);

                // Handle the response from the API, e.g., show success message, redirect to login page, etc.
                console.log('Signup successful:', response);

                // Reset the form after successful submission
                //setFormData(initialFormData);

                // Check the response from the API
                if (response.status && response.status==true) {
                    // Signup successful
                    alert(response.message);
                    //setSuccessMessage(response.message);
                    // setSuccessMessage(
                    //     <div>
                    //     {response.message}. Click <a href="/login" style={{ padding: '0' }}>here</a> to login.
                    //     </div>              
                    // );
                    // setErrorMessage(''); // Clear any existing error message
                    try {
                        //console.log('formData',formData);
                        const LoginServiceInstance = new LoginService();
                        const response = await LoginServiceInstance.login(formData);
        
                        // Handle the response from the API, e.g., show success message, redirect to login page, etc.
                        console.log('Login successful:', response);
        
                        // Reset the form after successful submission
                        //setFormData(initialFormData);
        
                        // Check the response from the API
                        if (response.status && response.status==true) {
                            // Signup successful
                            // setSuccessMessage(response.message);
                            // setErrorMessage(''); // Clear any existing error message
                            // // Reset the form after successful submission
                            // setFormData(initialFormData);
                            
                            localStorage.setItem('userid', response.data.userid);
                            localStorage.setItem('fullname', response.data.fullname);
                            localStorage.setItem('email', response.data.email);
                            localStorage.setItem('phonenumber', response.data.phonenumber);
                            localStorage.setItem('session_key', response.data.session_key);
                            localStorage.setItem('userlogo', response.data.userlogo);
                            localStorage.setItem('isLoggedIn', 1);
        
                            // Set the state to trigger redirection
                            //navigate('/profile');
                            if(urlparam=='bulkorder'){
                                window.location.href = '/bulk-order/11';
                                //navigate('/bulk-order/11');
                            }else if(urlparam=='product' && urlparam2!=''){
                                window.location.href = '/product-info/'+urlparam2;
                                //navigate('/bulk-order/11');
                            }else{
                                window.location.href = '/';
                            }
        
                        } else {
                            // Signup failed
                            setErrorMessage(response.message);
                            setSuccessMessage(''); // Clear any existing success message
                        }
        
                    } catch (error) {
        
                        setErrorMessage('Login failed:', error);
                        setSuccessMessage(''); // Clear any existing success message
        
                        // Handle the API error, e.g., show error message, log the error, etc.
                        console.error('Login failed:', error);
        
                    }
                    // Reset the form after successful submission
                    setFormData(initialFormData);
                } else {
                    // Signup failed
                    setErrorMessage(response.message);
                    setSuccessMessage(''); // Clear any existing success message
                }

            } catch (error) {

                setErrorMessage('Signup failed:', error);
                setSuccessMessage(''); // Clear any existing success message

                // Handle the API error, e.g., show error message, log the error, etc.
                console.error('Signup failed:', error);

            }
        }
    };

    // Function to validate form data
    const validateFormData = (data) => {
        const errors = {};

        // Perform your validation checks here
        // For example, checking if required fields are filled, valid email format, etc.

        // Example validation for required fields
        if (!data.fullname) {
            errors.fullname = 'Full Name is required';
        }

        if (!data.phonenumber) {
            errors.phonenumber = 'Phone Number is required';
        }

        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format';
        }

        if (!data.password) {
            errors.password = 'Password is required';
        }

        if (!data.confirmpassword) {
            errors.confirmpassword = 'Confirm Password is required';
        } else if (data.password !== data.confirmpassword) {
            errors.confirmpassword = 'Passwords do not match';
        }

        return errors;
    };


    // Helper function to check for valid email format
    const isValidEmail = (email) => {
        // You can use a regular expression or any other method to validate email format
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>Create New Account</h1>
                    <nav className="breadcrumb ps-4">
                        <a className="breadcrumb-item" href="/">Home</a>
                        <span className="breadcrumb-item active" aria-current="page">Signup</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row">
                            {/* <div className="col-lg-4">
                                <div className="list-group" id="list-tab" role="tablist">
                                    <a className="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home"><BiUser /> My Account</a>
                                    
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                {successMessage && <p className="success">{successMessage}</p>}
                                {errorMessage && <p className="error">{errorMessage}</p>}

                                <div className="tab-content" id="nav-tabContent">
                                    
                                    <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                        <form onSubmit={handleSubmit}>
                                            <div className='profile_info'>
                                                <div className="row">
                                                    <label>Full Name</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="text" name="fullname" id="fullname" value={formData.fullname} onChange={handleInputChange} /><br/>
                                                            {errors.fullname && <span className="error">{errors.fullname}</span>}
                                                        </div>
                                                    </div>
                                                    <label>Phone</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="text" name="phonenumber" id="phonenumber" value={formData.phonenumber} onChange={handleInputChange} /><br/>
                                                            {errors.phonenumber && <span className="error">{errors.phonenumber}</span>}
                                                        </div>
                                                    </div>
                                                    <label>Email</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} /><br/>
                                                            {errors.email && <span className="error">{errors.email}</span>}
                                                        </div>
                                                    </div>
                                                    <label>Password</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} /><br/>
                                                            {errors.password && <span className="error">{errors.password}</span>}
                                                        </div>
                                                    </div>
                                                    <label>Confirm Password</label>
                                                    <div className="col-lg-12">
                                                        <div>
                                                            <input type="password" name="confirmpassword" id="confirmpassword" value={formData.confirmpassword} onChange={handleInputChange} /><br/>
                                                            {errors.confirmpassword && <span className="error">{errors.confirmpassword}</span>}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button className='btn_2' type="submit">Signup</button>
                                                    </div>
                                                    <br/><br/><br/><hr/><br/><br/>
                                                    <div className='text-center' >
                                                        <NavLink to={`/login/${urlparam}/${urlparam2}`} className='btn_2'>Already have an account ?</NavLink>
                                                    </div>
                                                </div>
                                            </div> 
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='text-center'>
                                    <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                                    <h5>Continue Shopping</h5>
                                    <NavLink className="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='text-center'>
                        <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                        <h5>Continue Shopping</h5>
                        <NavLink className="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                    </div> */}
            
                </div>
            </section>
        </>
    )
}

export default Signup