import React, { useEffect, useState } from 'react'
import emptyCart from '../assets/icons/empty.png';
import { useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { Chip, Divider } from '@mui/material';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import CartService from '../services/CartService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddToCart() {

    const navigate = useNavigate(); // Initialize useNavigate hook

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false); // Add a state to track if the isLoggedIn check is complete
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const getData = useSelector((state) => state.cartreducer.carts)
    const [cartData, setCartData] = useState({ data: [] });
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
        }
    }, [navigate]);

    useEffect(() => {
        // Fetch cart data using CartDataService when the component mounts
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');
    
        const cartServiceInstance = new CartService();

        const sendCartData = {
            user_id: user_id,
            sessionkey: session_key,
            limit: ''
          };

          cartServiceInstance
          .cartlist(sendCartData)
          .then((data) => {
            setCartData(data);
          })
          .catch((error) => {
            console.error('Error fetching cart data:', error);
          });
      }, []);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    // Render a loading indicator while the isLoggedIn check is in progress
    if (!isLoggedInChecked) {
        return <div>Loading...</div>;
    }
    
    
      //console.log('cartData',cartData.data);
      //console.log('cartData',cartData.data?.length);
    const handleQuantityIncrease = async (product) => {
        setQuantity((prevQuantity) => Math.max(prevQuantity + 1, 1));
        //console.log('productArrrrr',product);

        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.product_id;
            var in_stockstatus = product.in_stock;
            var product_price_id = product.product_price_id;
            var price_qty = product.price_qty;
            var price_unit_name = product.price_unit_name;
            var qty = 1;
            var per_mrp_price = product.per_mrp_price;
            var total_mrp_price = (qty * product.per_mrp_price);
            var per_discount = product.discount_price;
            var total_discount = (qty * product.discount_price);
            var final_per_price = product.final_per_price;
            var final_total_price = (qty * product.final_per_price);
            
            const productData = {
              user_id: user_id,
              sessionkey: session_key,
              product_id: product_id,
              product_price_id: product_price_id,
              price_qty: price_qty,
              price_unit_name: price_unit_name,
              qty: qty,
              per_mrp_price: per_mrp_price,
              total_mrp_price: total_mrp_price,
              per_discount: per_discount,
              total_discount: total_discount,
              final_per_price: final_per_price,
              final_total_price: final_total_price
            };
            if(in_stockstatus==='INSTOCK'){
                const response = await cartService.addtocart(productData);
        
                // Assuming your API returns a success flag or some response indicating successful cart addition
                if (response.status==true) {
                //alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                //window.location.reload();
                
                    const cartServiceInstance = new CartService();

                    const sendCartData = {
                        user_id: user_id,
                        sessionkey: session_key,
                        limit: ''
                    };

                    cartServiceInstance
                    .cartlist(sendCartData)
                    .then((data) => {
                        setCartData(data);
                    })
                    .catch((error) => {
                        console.error('Error fetching cart data:', error);
                    });
                } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
                }
            }else{
                alert('Product is out of stock');
            }
          } catch (error) {
            console.error('Error adding product to cart:', error);
          }

    };

    const handleQuantityDecrease = async (product) => {
        setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
        //console.log('productArrrrr',product);

        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.product_id;
            var cart_id = product.cart_id;
            var product_price_id = product.product_price_id;
            var price_qty = product.price_qty;
            var price_unit_name = product.price_unit_name;
            
            const productData = {
              user_id: user_id,
              sessionkey: session_key,
              product_id: product_id,
              cart_id: cart_id,
              product_price_id: product_price_id,
              price_qty: price_qty,
              price_unit_name: price_unit_name
            };
            const response = await cartService.removeonetocart(productData);
      
            // Assuming your API returns a success flag or some response indicating successful cart addition
            if (response.status==true) {
              //alert(response.message);
              // Handle success, e.g., show a success message or redirect to the cart page
              //window.location.reload();
              
                const cartServiceInstance = new CartService();

                const sendCartData = {
                    user_id: user_id,
                    sessionkey: session_key,
                    limit: ''
                };

                cartServiceInstance
                .cartlist(sendCartData)
                .then((data) => {
                    setCartData(data);
                })
                .catch((error) => {
                    console.error('Error fetching cart data:', error);
                });
            } else {
              alert(response.message);
              // Handle failure, e.g., show an error message
            }
          } catch (error) {
            console.error('Error adding product to cart:', error);
          }

    };

    const removeToCart = async (product) => {
        //console.log('productArr',product);
        try {
            const cartService = new CartService();
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
            var product_id = product.product_id;
            var cart_id = product.cart_id;
            
            const productData = {
                user_id: user_id,
                sessionkey: session_key,
                product_id: product_id,
                cart_id: cart_id
            };
            const response = await cartService.deletetocart(productData);
        
            // Assuming your API returns a success flag or some response indicating successful cart addition
            if (response.status==true) {
                //alert(response.message);
                // Handle success, e.g., show a success message or redirect to the cart page
                // After successful removal, fetch the updated cart data and update the state
                const cartServiceInstance = new CartService();

                const sendCartData = {
                    user_id: user_id,
                    sessionkey: session_key,
                    limit: ''
                };

                cartServiceInstance
                .cartlist(sendCartData)
                .then((data) => {
                    setCartData(data);
                })
                .catch((error) => {
                    console.error('Error fetching cart data:', error);
                });
            } else {
                alert(response.message);
                // Handle failure, e.g., show an error message
            }
        } catch (error) {
            console.error('Error remove product to cart:', error);
        }
    };

    // Calculate the total price and total discount
    const totalPrice = cartData.data?.reduce((total, item) => total + parseFloat(item.total_mrp_price), 0) || 0;
    const totalDiscount = cartData.data?.reduce((total, item) => total + parseFloat(item.total_discount), 0) || 0;
    const totalAmount = cartData.data?.reduce((total, item) => total + parseFloat(item.final_total_price), 0) || 0;

    
    return (
        <>
            <section className='addto_cart'>
                <div className="container">
                    <nav class="breadcrumb mt-4 mb-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">Cart</span>
                    </nav>
                    {
                        cartData.data?.length
                            ?
                            <div className='cart_item'>
                                <hr />
                                {
                                    cartData.data.map((e) => {
                                        return (
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div>
                                                            <NavLink to={`/product-info/${e.product_id}`}><img src={e.image} className='img-fluid' /></NavLink>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9 d-flex justify-content-center flex-column">
                                                        <div className='p-3'>
                                                            <h5><NavLink to={`/product-info/${e.product_id}`}>{e.product_name}</NavLink></h5>
                                                            <p className='in_stock'>{e.in_stock}</p>
                                                            <div>
                                                               <strong>MRP : </strong> ₹ {e.final_per_price}
                                                            </div>
                                                            <p>
                                                                <strong>QTY : </strong>{e.qty}</p>
                                                            <ul className='d-flex justify-content-between p-0'>
                                                                <li className='me-4'>
                                                                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                                        <button type="button" class="btn btn-outline-primary" onClick={() => handleQuantityDecrease(e)}>-</button>
                                                                        <button type="button" class="btn btn-outline-primary">{e.qty}</button>
                                                                        <button type="button" class="btn btn-outline-primary" onClick={() => handleQuantityIncrease(e)}>+</button>
                                                                    </div>

                                                                </li>
                                                                <li>
                                                                    {/* <h4><AiFillDelete onClick={()=> removeToCart(e)} /></h4> */}
                                                                    <IconButton aria-label="delete" onClick={()=> removeToCart(e)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                <div className='pd_details'>
                                    <h3>Price Details</h3>
                                    <hr />
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <h6>Price</h6>
                                            <h6>₹ {totalPrice.toFixed(2)}</h6>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <h6>Discount</h6>
                                            <h6>-₹ {totalDiscount.toFixed(2)}</h6>
                                        </div>
                                        {/* <div className="d-flex justify-content-between">
                                            <h6>Delivery Charge</h6>
                                            <h6>₹100</h6>
                                        </div> */}
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <h6>Total Amount</h6>
                                            <h6>₹ {totalAmount.toFixed(2)}</h6>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <h6 style={{ color: 'green' }}>You will Save ₹ {totalDiscount} in this order</h6>
                                            <NavLink to='/checkout'>
                                                <button className='btn_1'>Checkout</button>
                                            </NavLink>


                                        </div>

                                    </div>
                                </div>
                                {/* <Button variant="outlined" onClick={handleClickOpen}>
                                    Slide in alert dialog
                                </Button> */}
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                    style={{ maxWidth: '100%', width: '600px', margin: 'auto', display: 'block' }}
                                >
                                    <DialogTitle className='text-center'>{"Welcome to Liebigs Agro Chem"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            <div className='login_form'>
                                                <div className="form">
                                                    <div className="row">
                                                        <label htmlFor=""><b>Email Or Phone Number</b></label>
                                                        <div className="col-lg-12">
                                                            <input type="text" name="email" />
                                                        </div>
                                                        <label htmlFor=""><b>Password</b></label>
                                                        <div className="col-lg-12">
                                                            <input type="password" name="password" />
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <button className='btn_1'>Log In</button>
                                                        </div>
                                                        <Divider className='mt-4' style={{alignItems:'flex-start'}}>
                                                            <Chip label="OR" />
                                                        </Divider>
                                                        <div >
                                                            <ul className='mt-3 p-0 d-flex justify-content-center'>
                                                                <li className='me-2'><FcGoogle/></li>
                                                                <li><FaFacebook style={{color:'	#3b5998'}}/></li>
                                                            </ul>                   
                                                        </div>
                                                        <div>
                                                            <ul className='d-flex justify-content-between p-0'>
                                                                <li onClick={()=>{handleClickOpen1();handleClose();}} style={{cursor:'pointer',borderBottom:'1px solid gray',fontSize:'small'}}>Don't have an account?</li>
                                                                <li style={{cursor:'pointer',borderBottom:'1px solid gray',fontSize:'small'}}>Forget Password</li>
                                                            </ul>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                                <div>
                                <Dialog
                                    open={open1}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose1}
                                    aria-describedby="alert-dialog-description"
                                    style={{ maxWidth: '100%', width: '600px', margin: 'auto', display: 'block' }}
                                >
                                    <DialogTitle className='text-center'>{"Welcome to Liebigs Agro Chem"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className='login_form'>
                                                <div className="form">
                                                    <div className="row">
                                                        <label htmlFor=""><b>Name</b></label>
                                                        <div className="col-lg-6">
                                                            <input type="text" name="fname" placeholder='First Name' />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input type="text" name="lname" placeholder='Last Name' />
                                                        </div>
                                                        <label htmlFor=""><b>Email Id</b></label>
                                                        <div className="col-lg-12">
                                                            <input type="text" name="lname" />
                                                        </div>
                                                        <label htmlFor=""><b>Password</b></label>
                                                        <div className="col-lg-12">
                                                            <input type="password" name="password" placeholder='Current Password' />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <input type="password" name="password" placeholder='New Password' />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <input type="password" name="password" placeholder='Confirm Password' />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <button className='btn_1'>Create Account</button>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <ul className='p-0 mt-4'>
                                                                <li className='text-center' style={{fontSize:'small'}}>Already have an account? <span onClick={()=>{handleClickOpen();handleClose1();}} style={{color:'#0d6efd',cursor:'pointer'}}>Login Here!</span></li>
                                                            </ul>
                                                            
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                                </div>
                               
                            </div>
                            :
                            <div className='mt-5 mb-5'>
                                <img src={emptyCart} alt="empty-cart" className='img-fluid d-block m-auto' width="220" />
                                <p className='fs-4 text-center'>No items found in cart</p>
                            </div>

                    }

                </div>
            </section>
        </>
    )
}

export default AddToCart