import React, { useState, useEffect } from 'react';
import MyOrder from './MyOrder';
import MyTransaction from './MyTransaction';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';
import defaultuserlogo from '../assets/img/users/default.jpg';
import { NavLink } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import LogoutService from '../services/LogoutService';
import ProfileService from '../services/ProfileService';

function MyAccount() {
    
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [isLoggedInChecked, setIsLoggedInChecked] = useState(false); // Add a state to track if the isLoggedIn check is complete
    const [userProfileData, setUserProfileData] = React.useState([]);
    
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            // Set the isLoggedInChecked state to true once the check is complete
            setIsLoggedInChecked(true);
        }
    }, [navigate]);

    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        // Redirect to logout service if the user is not logged in
        if (!isLoggedIn || isLoggedIn === 0) {
            navigate('/login');
        } else {
            
            // Fetch cart data using CartDataService when the component mounts
            var user_id = localStorage.getItem('userid');
            var session_key = localStorage.getItem('session_key');
    
            const ProfileServiceInstance = new ProfileService();
    
            const sendUserData = {
                user_id: user_id,
                sessionkey: session_key
            };
    
            ProfileServiceInstance
                .getProfileDetails(sendUserData)
                .then((data) => {
                    //console.log('data.data',data.data);
                    if (Array.isArray(data.data)) {
                        setUserProfileData(data.data);
                    } else {
                        setUserProfileData(data.data);
                        //console.error('Unexpected order data structure:', data);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching order data:', error);
                });
        }
    }, [navigate]);

    const handleProfileUpdate = () => {

        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');

        const updatedProfileData = {
          user_id: user_id,
          sessionkey: session_key,
          fullname: document.getElementById('fullname').value,
          phonenumber: document.getElementById('phonenumber').value,
          // Add other fields here
        };
      
        const ProfileServiceInstance = new ProfileService();
      
        ProfileServiceInstance
          .updateProfileDetails(updatedProfileData)
          .then((response) => {
            // Handle success, maybe show a success message to the user
            alert(response.message);
          })
          .catch((error) => {
            console.error('Error updating profile:', error);
          });
    };


    const handleChangePassword = () => {
        const currentPassword = document.getElementById('currentpassword').value;
        const newPassword = document.getElementById('newpassword').value;
        const confirmPassword = document.getElementById('confirmpassword').value;
      
        // Check if newPassword and confirmPassword match
        if(currentPassword===''){
            alert("Enter current password.");
            // You might want to display an error message to the user here
            return;
        }else if(newPassword===''){
            alert("Enter new password.");
            // You might want to display an error message to the user here
            return;
        }else if(confirmPassword===''){
            alert("Enter confirm password.");
            // You might want to display an error message to the user here
            return;
        }else if (newPassword !== confirmPassword) {
            alert("New password and confirm password do not match.");
            // You might want to display an error message to the user here
            return;
        }
        
        var user_id = localStorage.getItem('userid');
        var session_key = localStorage.getItem('session_key');

        const newPasswordData = {
          user_id: user_id,
          sessionkey: session_key,
          currentpassword: currentPassword,
          newpassword: newPassword
        };
      
        const ProfileServiceInstance = new ProfileService();
      
        ProfileServiceInstance
          .changepassword(newPasswordData)
          .then((response) => {
            // Handle success, maybe show a success message to the user
            alert(response.message)
          })
          .catch((error) => {
            console.error('Error changing password:', error);
          });
    };
      
    // Render a loading indicator while the isLoggedIn check is in progress
    if (!isLoggedInChecked) {
        return <div>Loading...</div>;
    }

    
    
    
    const handleLogout = () => {
        // Get session_key and userid from localStorage
        const sessionKey = localStorage.getItem('session_key');
        const userId = localStorage.getItem('userid');
    
        // Call the logout API service passing session_key and userid
        const logoutServiceInstance = new LogoutService();
        logoutServiceInstance
          .logout(sessionKey, userId)
          .then((response) => {
            // Clear user data and set isLoggedIn to false in local storage
            localStorage.removeItem('userid');
            localStorage.removeItem('fullname');
            localStorage.removeItem('email');
            localStorage.removeItem('phonenumber');
            localStorage.removeItem('session_key');
            localStorage.removeItem('userlogo');
            localStorage.removeItem('isLoggedIn');
    
            // Redirect to the home page after logout
            navigate('/login');
          })
          .catch((error) => {
            // Handle API error if needed
            console.error('Logout failed:', error);
            // Even if there's an error, you can still clear user data and redirect if needed
            localStorage.removeItem('userid');
            localStorage.removeItem('fullname');
            localStorage.removeItem('email');
            localStorage.removeItem('phonenumber');
            localStorage.removeItem('session_key');
            localStorage.removeItem('userlogo');
            localStorage.removeItem('isLoggedIn');
            navigate('/login');
          });
      };

    //console.log('setUserProfileData',userProfileData.fullname);

    const fullname = localStorage.getItem('fullname');
    const email = localStorage.getItem('email');
    const phonenumber = localStorage.getItem('phonenumber');

    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>My Account</h1>
                    <nav class="breadcrumb ps-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">My Account</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row gy-3">
                            <div className="col-lg-4">
                                <div className="list-group" id="list-tab" role="tablist">
                                    <a className="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home"><BiUser /> My Account</a>
                                    <a className="list-group-item list-group-item-action" id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile"><HiOutlineClipboardList/> My Order</a>
                                    <a className="list-group-item list-group-item-action" id="list-transaction-list" data-bs-toggle="list" href="#list-transaction" role="tab" aria-controls="list-transaction"><HiOutlineClipboardList/> Transaction History</a>
                                    <a className="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages"><ImProfile/> Update Profile</a>
                                    <a className="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings"><RiLockPasswordFill/> Change Password</a>
                                    <a className="list-group-item list-group-item-action" id="list-logout-list" data-bs-toggle="list" href="#" role="tab" aria-controls="list-settings" onClick={handleLogout}><MdOutlineLogout/> Sign Out</a>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                        <div className="d-flex align-item-baseline" style={{gap:'20px'}}>
                                            <div className="">
                                                
                                                    <img src={defaultuserlogo} alt="profile_picture" className='img-fluid rounded-circle' width="120"/>
                                                
                                            </div>
                                            <div className="">
                                                
                                                    <h3 className='text-center text-lg-start'>Hello... <span className='display-6'>{fullname}</span></h3>
                                                    <p>
                                                        <strong>Email :</strong> {email}
                                                    </p>
                                                    <p>
                                                        <strong>Phone :</strong> {phonenumber}
                                                    </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                        <MyOrder />
                                    </div>
                                    <div className="tab-pane fade" id="list-transaction" role="tabpanel" aria-labelledby="list-transaction-list">
                                        <MyTransaction />
                                    </div>
                                    <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                                    <div className='profile_info pt-lg-0 pt-3'>
                                        <div className="row">
                                            {/* <div className="col-lg-12 mb-4">
                                                <label htmlFor="">Profile Image</label>
                                                <div>
                                                    <input type="file" id="img1" name="img" accept="image/*" />
                                                </div>
                                            </div> */}
                                            <div className='d-lg-flex mb-2'>
                                                <div className='me-3 profile_input'>
                                                    <lable>Full Name</lable>
                                                    <div>
                                                        <input type="text" id="fullname" name="fullname" defaultValue={userProfileData.fullname} />
                                                    </div>
                                                </div>
                                                <div className='profile_input'>
                                                    <lable>Email Address</lable>
                                                    <div>
                                                        <input type="text" id="email" name="email" value={email} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-lg-flex'>
                                                <div className='me-3 profile_input'>
                                                    <lable>Phone / Mobile</lable>
                                                    <div>
                                                        <input type="text" id="phonenumber" name="phonenumber" defaultValue={userProfileData.phonenumber} />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div>
                                                <button className='btn_2' onClick={handleProfileUpdate}>Update Profile</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
                                    <div className='profile_info'>
                                        <div className="row">
                                            <label>Current Password</label>
                                            <div className="col-lg-12">
                                                <div>
                                                    <input type="password" name="currentpassword" id="currentpassword" required />
                                                </div>
                                            </div>
                                            <label>New Password</label>
                                            <div className="col-lg-12">
                                                <div>
                                                    <input type="password" name="newpassword" id="newpassword" required />
                                                </div>
                                            </div>
                                            <label>Confirm Password</label>
                                            <div className="col-lg-12">
                                                <div>
                                                    <input type="password" name="confirmpassword" id="confirmpassword" required />
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn_2' onClick={handleChangePassword}>Change Password</button>
                                            </div>
                                        </div>
                                    </div> 
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                        <h5>Continue Shopping</h5>
                        <NavLink class="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                    </div>
            
                </div>
            </section>
        </>
    )
}

export default MyAccount