import React from 'react';
import { Link, useNavigate, useParams, NavLink } from 'react-router-dom';
import Dashboard from './Dashboard';
import { BiUser } from 'react-icons/bi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { ImProfile } from 'react-icons/im';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdOutlineLogout } from 'react-icons/md';

import icon1 from '../assets/icons/shopping-cart.png';


function ForgotPassword() {

    const { param, param2 } = useParams();

    const urlparam = param ? param : '';
    const urlparam2 = param2 ? param2 : '';

    return (
        <>
            <section className='pt-4 pb-4'>
                <div className="container">
                    <h1 className='ps-4'>Forgotten password?</h1>
                    <nav class="breadcrumb ps-4">
                        <a class="breadcrumb-item" href="/">Home</a>
                        <span class="breadcrumb-item active" aria-current="page">Forgot-Password</span>
                    </nav>
                    <div className='profile_header'>
                        <div className="row">
                            {/* <div className="col-lg-4">
                                <div className="list-group" id="list-tab" role="tablist">
                                    <a className="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home"><BiUser /> My Account</a>
                                    
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="tab-content" id="nav-tabContent">
                                    
                                    <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                    <div className='profile_info'>
                                        <div className="row">
                                            <label>Email</label>
                                            <div className="col-lg-12">
                                                <div>
                                                    <input type="text" name="cp" id="" />
                                                </div>
                                            </div>
                                            <div className='text-right' style={{ textAlign: 'right' }} >
                                                <NavLink to={`/login/${urlparam}/${urlparam2}`}>Do you have an account ?</NavLink>
                                            </div>
                                            <div>
                                                <button className='btn_2'>Send</button>
                                            </div>
                                            <br/><br/><br/><hr/><br/><br/>
                                            <div className='text-center' >
                                                <NavLink to={`/signup/${urlparam}/${urlparam2}`} className='btn_2'>Create New Account</NavLink>
                                            </div>
                                        </div>
                                    </div> 
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='text-center'>
                                    <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                                    <h5>Continue Shopping</h5>
                                    <NavLink class="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='text-center'>
                        <img src={icon1} alt="shoppingCart" className='img-fluid' width="300px"/>
                        <h5>Continue Shopping</h5>
                        <NavLink class="btn btn-primary" to="/" role="button">Shop Now</NavLink>
                    </div> */}
            
                </div>
            </section>
        </>
    )
}

export default ForgotPassword